<!-- 设备管理-保养管理-保养工单 -->
<template>
    <div class="page_container height_100">
        <div class="bgc_FFFFFF height_100">
            <div class="box_head1">
                <span>保养工单</span>
                <div class="flex align_center fn_size14 mg_left_50">
                    <div>设备编号：</div>
                    <el-input v-model="deviceID" placeholder="请输入设备编号" class="mg_left_8 width_150 "></el-input>
                    <div class="mg_left_20">工单状态：</div>
                    <el-select v-model="workOrderStatusValue" placeholder="请选择" class="mg_left_8 width_150" clearable>
                        <el-option v-for="item in workOrderStatus" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <!-- <div class="mg_left_20">评分等级：</div>
                    <el-select v-model="gradingArrValue" placeholder="请选择" class="mg_left_8 width_150" clearable>
                        <el-option v-for="item in gradingArr" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                    <div class="mg_left_20">保养类型：</div>
                    <el-select v-model="maintainCategoryValue" placeholder="请选择" class="mg_left_8 width_150" clearable>
                        <el-option v-for="item in maintainCategory" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <div class="mg_left_20">保养时间：</div>
                    <el-date-picker value-format="timestamp" v-model="maintainTimeValue" type="datetimerange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
                    </el-date-picker>
                    <el-button type="primary" class=" mg_left_10" size="medium"
                        @click="query">查询
                    </el-button>
                </div>
            </div>
            
            <div class="flex justify_between pdt_20 fn_size16 pdl30 pdr30">
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_120"
                        style=" background: #03CABE;border: none;" @click="gotoFastOrder">添加工单</el-button>
                        <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #03CABE;border: none;">导出</el-button>
                </div>
                <!-- <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出
                </el-button> -->
            </div>
            <!-- <div class="height_500"> -->
            <div class="pdl30 pdr30" style="height:78%">
                <el-table ref="multipleTable" :data="maintainOrderList" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" height="100%" @selection-change="handleSelectionChange"
                     @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                    <!-- <el-table-column type="selection" width="55">
                    </el-table-column> -->
                    <el-table-column type="index" label="序号" width="80">
                    </el-table-column>
                    <el-table-column prop="orderID" label="工单编号" width="">
                    </el-table-column>
                    <el-table-column prop="deviceID" label="设备编号" width="">
                    </el-table-column>
                    <el-table-column prop="deviceName" label="设备名称">
                    </el-table-column>
                     <el-table-column prop="isOutEntrust" label="是否委外">
                         <template slot-scope="scope">
                            <span>{{scope.row.isOutEntrust?'是':'否'}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column prop="orderStatus_name" label="保养工单状态" width="">
                    </el-table-column>
                    <el-table-column prop="deviceLocation" label="设备所在地点">
                    </el-table-column>
                    <el-table-column prop="execType_name" label="保养类型">
                    </el-table-column>
                    <el-table-column prop="authRoleName" label="审批角色">
                    </el-table-column>
                    <el-table-column prop="oprTime" label="创建时间" width="160px" :formatter="formatDate"> </el-table-column>
                    <el-table-column prop="oprPerson" label="创建人"> </el-table-column>
                    <el-table-column label="详情">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;" @click="gotoDetails(scope.row.ID)">详细</span>
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="320">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                v-if="scope.row.orderStatus!=8&&scope.row.orderStatus!=9&&scope.row.orderStatus!=11">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="gotoEditOrder(scope.row.ID)">修改</span>
                            </el-button>
                                <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="scope.row.orderStatus<4 && scope.row.doDelete == true">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="delMaintainOrder(scope.row.ID)">删除</span>
                                </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="scope.row.doAuth">
                                <span style="border-bottom: 1px solid #009BFD "
                                    @click="approvalOperation(scope.row.ID)">审批</span>
                            </el-button>
                            <!-- <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="!scope.row.doAuth">
                                已审批</el-button> -->
                                <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                    v-if="scope.row.orderStatus==1 && scope.row.doDispatch == true">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="dispatchOperation(scope.row.ID)">派单</span>
                                </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                v-if="scope.row.orderStatus==4">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="receivingOrders(scope.row.ID)">接单</span>
                            </el-button>

                            <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                v-if="scope.row.orderStatus==5">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="implementOperation(scope.row.ID)">执行</span>
                            </el-button>

                            <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                v-if="scope.row.orderStatus==6">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="temporarilyPartEnd(scope.row.ID)">暂离结束</span>
                            </el-button>

                            <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                v-if="scope.row.orderStatus==7">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="temporarilyPart(scope.row.ID)">暂离</span>
                            </el-button>
                                <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                    v-if="scope.row.orderStatus==8 && scope.row.doVerify == true">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="verificationOperation(scope.row.ID)">验证</span>
                                </el-button>
                                <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                    v-if="scope.row.orderStatus==9 && scope.row.doGrade == true">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="scoreOperation(scope.row.ID)">评分</span>
                                </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                v-if="scope.row.orderStatus==10 ||scope.row.orderStatus==7">
                                <span style="border-bottom: 1px solid #009BFD;" @click="orderStatement(scope.row)">结单</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
            <el-dialog :visible.sync="dialogVisible" width="30%" top="30vh">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">审批</div>
                <div class="height_110 flex justify_center align_center">
                    <el-radio-group v-model="approvalListValue" class="">
                        <el-radio v-for="item in approvalList" :key="item.value" :label="item.value">
                            {{item.description}}
                        </el-radio>
                    </el-radio-group>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confrimApproval">确 定</el-button>
                </div>
            </el-dialog>

            <el-dialog :visible.sync="dialogVisibleDispatch" width="25%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">派单</div>
                <!-- <div class="flex flex_column "> -->
                <div class="flex justify_center mg_top_20">
                    <el-form :model="formDispatch" :label-width="formLabelWidth" :rules="rulesFrom">
                        <el-form-item label="是否委外：" class="" >
                            <el-checkbox v-model="formDispatch.isOutEntrust" class="width_220 text_align_left">
                            </el-checkbox>
                        </el-form-item>
                        <el-form-item label="保养组：" v-if="!formDispatch.isOutEntrust" prop="execGroup">
                            <el-select v-model="formDispatch.execGroup" placeholder="请选择" class="">
                                <el-option v-for="item in maintenance" :key="item.value" :label="item.description"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="保养人：" v-if="!formDispatch.isOutEntrust">
                            <el-select v-model="formDispatch.execPerson" placeholder="请选择" class="" multiple
                                collapse-tags>
                                <el-option v-for="(item,index) in maintenancePeople" :key="index" :label="item.userName"
                                    :value="item.userName">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="保养预估花费时间(分钟)：" v-if="!formDispatch.isOutEntrust" prop="execEstimateLen">
                            <el-input v-model="formDispatch.execEstimateLen" type="number" class="width_220" oninput="if(value<0){value=0}">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="指定保养开始时间：" v-if="!formDispatch.isOutEntrust" prop="assignTakeTime">
                            <el-date-picker value-format="timestamp" v-model="formDispatch.assignTakeTime" type="datetime" placeholder="选择日期时间"
                                class="">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="指定保养结束时间：" v-if="!formDispatch.isOutEntrust" prop="assignEndTime">
                            <el-date-picker value-format="timestamp" v-model="formDispatch.assignEndTime" type="datetime" placeholder="选择日期时间"
                                class="">
                            </el-date-picker>
                        </el-form-item>

                    </el-form>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogVisibleDispatch = false">取 消</el-button>
                    <el-button type="primary" @click="confirmChoicesEdit">确 定</el-button>
                </div>
            </el-dialog>

            <el-dialog :visible.sync="dialogVisibleImplement" width="30%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">执行</div>
                <div class="height_110 flex justify_center align_center">
                    <el-form :model="formImplement">
                        <el-form-item label="执行类型：" :label-width="formLabelWidth">
                            <el-select v-model="formImplement.orderExecType" placeholder="请选择" class="">
                                <el-option v-for="item in executeType" :key="item.value" :label="item.description"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                    </el-form>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogVisibleImplement = false">取 消</el-button>
                    <el-button type="primary" @click="confrimImplement">确 定</el-button>
                </div>
            </el-dialog>

            <el-dialog :visible.sync="dialogVisibleStatement" width="28%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">结单</div>
                <!-- <div class="flex flex_column "> -->
                <div class="flex justify_center mg_top_20">
                    <el-form :model="formStatement" :label-width="formLabelWidth2" :rules="rulesFrom" ref="formStatement" >
                        <el-form-item label="结单类型：" prop="orderFinishType">
                            <el-select v-model="formStatement.orderFinishType" placeholder="请选择" class="">
                                <el-option v-for="item in finishTypeQuery" :key="item.value" :label="item.description"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否停机：" class="">
                            <el-checkbox v-model="formStatement.machineStop" class="width_220 text_align_left">
                            </el-checkbox>
                        </el-form-item>
                        <el-form-item label="停机时长：" v-if="formStatement.machineStop">
                            <el-input v-model="formStatement.stopLen" type="number" autocomplete="off"
                                class="width_220">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="工作描述：" prop="execDesc">
                            <el-input v-model="formStatement.execDesc" autocomplete="off" class="width_220">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="现场状况：" prop="spotCondition">
                            <el-input v-model="formStatement.spotCondition" autocomplete="off" class="width_220">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="保养费用(元)：" >
                            <!-- prop="execFee" -->
                            <el-input v-model="formStatement.execFee" type="number" oninput="if(value<0){value=0}" autocomplete="off"
                                class="width_220">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="备注：">
                            <el-input v-model="formStatement.remarks" autocomplete="off" class="width_220">
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogVisibleStatement = false">取 消</el-button>
                    <!-- <el-button type="primary" @click="confirmStatement('formStatement')">确 定</el-button> -->
                    <el-button type="primary" @click="confirmStatement()">确 定</el-button>
                </div>
            </el-dialog>

            <el-dialog :visible.sync="dialogVisibleVerification" width="30%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">验证</div>
                <!-- <div class="flex flex_column "> -->
                <div class="flex justify_center mg_top_20">
                    <el-form :model="formStatement">
                        <el-form-item label="是否通过：" :label-width="formLabelWidth" class="">
                            <el-checkbox v-model="formStatement.verifyResult" class="width_300 text_align_left">
                            </el-checkbox>
                        </el-form-item>
                        <el-form-item label="验证备注：" :label-width="formLabelWidth">
                            <el-input v-model="formStatement.verifyRemarks" autocomplete="off" class="">
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogVisibleVerification = false">取 消</el-button>
                    <el-button type="primary" @click="confirmVerification">确 定</el-button>
                </div>
            </el-dialog>

            <el-dialog :visible.sync="dialogVisibleScore" width="30%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">评分</div>
                <!-- <div class="flex flex_column "> -->
                <div class="flex justify_center mg_top_20">
                    <el-form :model="formStatement">
                        <el-form-item label="工单评分等级：" :label-width="formLabelWidth" class="">
                            <el-select v-model="formStatement.execGrade" placeholder="全部" class=" ">
                                <el-option v-for="item in gradingArr" :key="item.value" :label="item.description"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogVisibleScore = false">取 消</el-button>
                    <el-button type="primary" @click="confirmScore">确 定</el-button>
                </div>
            </el-dialog>
            <!-- </div> -->
            <!-- </div> -->
            <!-- <div class="tab_horizontal mg_top_24">
                <div class="tab_box mg_left_30 tab_box_active">保养明细</div>
            </div>
            <div class="mg_top_20 pdb_20 height_300">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" max-height="250">
                    <el-table-column type="index" label="序号" width="55">
                    </el-table-column>
                    <el-table-column prop="name" label="保养位置" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="保养标准" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="正常">
                    </el-table-column>
                    <el-table-column prop="name" label="备注">
                    </el-table-column>
                </el-table>
            </div> -->
        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        maintainOrderMultiAuthQueryLimit,
        // maintainOrderInsert,
        maintainOrderDelete,
        // maintainOrderUpdate,
        maintainOrderStatusQuery,
        maintainOrderExecGradeQuery,
        getMaintainCategory,


        maintainOrderAuthorize,
        multiAuthorizeResultQuery,
        getMaintainExecGroup,
        maintainExecPersonQuery,
        maintainOrderDispatch,
        maintainOrderTake,
        maintainOrderExecute,
        maintainOrderPause,
        maintainOrderContinue,
        maintainOrderMaintainFinish,
        maintainOrderVerify,
        maintainOrderMarkEmpirical,
        maintainOrderMaintainGrade,
        maintainOrderExecuteTypeQuery,
        maintainOrderFinishTypeQuery
    } from "@/api/api_deviceManagement";
    import {
        rMIGradeSettingsQuery
    } from "@/api/api_systemConfig";
    // 
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                rulesFrom:{
                     orderFinishType: [{
                        required: true,
                        message: '请选择结单类型',
                        trigger: 'blur'
                    }],
                    // machineStop:[{
                    //     required: true,
                    //     message: '请选择勾选是否停机',
                    //     trigger: 'blur'   
                    // }],
                    stopLen:[{
                        required: true,
                        message: '请选输入停机时长(分钟)',
                        trigger: 'blur' 
                    }],
                    execDesc:[{
                        required: true,
                        message: '请选输入工作描述',
                        trigger: 'blur' 
                    }],
                    spotCondition:[{
                        required: true,
                        message: '请选输入现场状况',
                        trigger: 'blur' 
                    }],
                    execFee:[{
                        required: true,
                        message: '请选输入维修费用(元)',
                        trigger: 'blur' 
                    }],
                    isOutEntrust:[{
                        required: true,
                        message: '请勾选是否委外',
                        trigger: 'blur' 
                    }],
                    execGroup:[{
                        required: true,
                        message: '请选择保养组',
                        trigger: 'blur' 
                    }],
                    execEstimateLen:[{
                        required: true,
                        message: '请填写保养预估花费时间(分钟)',
                        trigger: 'blur' 
                    }],
                    assignTakeTime:[{
                        required: true,
                        message: '请选择指定保养开始时间',
                        trigger: 'blur' 
                    }],
                    assignEndTime:[{
                        required: true,
                        message: '请选择指定保养结束时间',
                        trigger: 'blur' 
                    }]
                },
                tabIndex: 0,
                value: '',
                multipleSelection: [],
                maintainOrderList: [],
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                workOrderStatusValue: '',
                deviceID:'',
                workOrderStatus: [],
                gradingArrValue: '',
                gradingArr: [],
                maintainCategoryValue: '',
                maintainCategory: [],
                maintainTimeValue: null,
                userID: '',
                dialogVisible: false,
                approvalListValue: undefined,
                approvalList: [],
                dialogVisibleDispatch: false,
                formDispatch: {},
                formLabelWidth: '110px',
                formLabelWidth2: '110px',
                maintenance: [],
                maintenancePeople: [],
                dialogVisibleImplement: false,
                options: [{
                    value: '0',
                    label: '黄金糕'
                }],
                formImplement: {},
                dialogVisibleStatement: false,
                formStatement: {},
                dialogVisibleVerification: false,
                dialogVisibleScore: false,
                executeType: [],
                finishTypeQuery: [],
                verification:'',
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
            }
            this.maintainOrderMultiAuthQueryLimit();
            this.maintainOrderStatusQuery();
            this.maintainOrderExecGradeQuery();
            this.getMaintainCategory();


        },
        methods: {
               //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '保养工单')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },
            async rMIGradeSettingsQuery() {
                var parame = {
                    // userID:'user_ls'
                }
                const selectRes = await rMIGradeSettingsQuery(parame);
            },
            async maintainOrderStatusQuery() {
                var param = {
                    userID: this.userID,
                } //用户登录时
                const selectRes = await maintainOrderStatusQuery(param);
                this.workOrderStatus = selectRes.data
            },
            async maintainOrderExecGradeQuery() {
                var param = {
                    userID: this.userID,
                } //用户登录时
                const selectRes = await maintainOrderExecGradeQuery(param);
                this.gradingArr = selectRes.data
            },
            async getMaintainCategory() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await getMaintainCategory(param);
                this.maintainCategory = selectRes.data
            },
            async maintainOrderMultiAuthQueryLimit() {
                var param = {
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                    userID: this.userID,
                    
                } //用户登录时
                if(this.deviceID){
                    param.deviceID = this.deviceID;
                }
                if (this.workOrderStatusValue.toString() != '' && this.workOrderStatusValue != null) {
                    param.orderStatus = this.workOrderStatusValue
                }
                if (this.gradingArrValue != '' && this.gradingArrValue != null) {
                    param.execLevel = this.gradingArrValue
                }
                if (this.maintainCategoryValue != '' && this.maintainCategoryValue != null) {
                    param.execType = this.maintainCategoryValue
                }
                if (this.maintainTimeValue != null) {
                    param.startExecTime = this.maintainTimeValue[0];
                    param.endExecTime = this.maintainTimeValue[1];
                }
                const selectRes = await maintainOrderMultiAuthQueryLimit(param);
                this.maintainOrderList = selectRes.array;
                this.pageQuantity = selectRes.pageQuantity;
            },
            query() {
                this.currentPage = 1;
                this.maintainOrderMultiAuthQueryLimit();
            },
            handleCurrentChange(val) {
                // this.PageNumber = val;
                this.maintainOrderMultiAuthQueryLimit(); //查询table数据的方法
            },
            async delMaintainOrder(id) {
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentPage = this.delData.delData(this.maintainOrderList.length, this
                        .pageQuantity, this.currentPage)
                    this.maintainOrderDelete(id);
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });

                // maintainOrderDelete
            },
            async maintainOrderDelete(id) {
                var param = {
                    ID: id
                }
                const selectRes = await maintainOrderDelete(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.maintainOrderMultiAuthQueryLimit();
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            gotoFastOrder() {
                this.$router.push({
                    path: '/deviceManagement/maintainManagement/maintainOrder/fastOrder',
                    // query:{setid:111222}
                    // params:{setid:111222}//地址栏中看不到参数的内容
                })
            },
            gotoEditOrder(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/maintainManagement/maintainOrder/editOrder',
                })
            },
            gotoDetails(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/maintainManagement/maintainOrder/orderDetails',
                    // query:{setid:111222}
                    // params:{setid:111222}//地址栏中看不到参数的内容
                })
            },

            // 审批--开始
            async approvalOperation(id) {
                // if (this.multipleSelection.join(',') == '') {
                //     this.$message({
                //         message: '警告：请选择需要审批的数据！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                // if (this.multipleSelection.length > 1) {
                //     this.$message({
                //         message: '警告：请选择一条需要审批的数据！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.currentID = id;
                const selectRes = await multiAuthorizeResultQuery();
                this.approvalList = selectRes.array;
                // this.approvalListValue = selectRes.array[0].value
                this.dialogVisible = true;
            },
            async confrimApproval() {
                if(this.approvalListValue == undefined){
                    this.$message.error('请选择是否通过！');  
                }else{
                    var param = {
                        userID: this.userID,
                        ID: this.currentID,
                        authResult: this.approvalListValue
                    }
                    const selectRes = await maintainOrderAuthorize(param);
                    if (selectRes.data.result) {
                        this.$message({
                            message: selectRes.message,
                            type: 'success'
                        });
                        //审批结束后刷新页面
                        this.maintainOrderMultiAuthQueryLimit();
                        this.approvalListValue={},
                        this.dialogVisible = false;
                        this.approvalListValue = undefined
                    } else {
                        this.$message.error(selectRes.message);
                    }
                }
            },
            // 审批结束
            //  派单--开始
            dispatchOperation(id) {
                this.currentID = id;
                this.formDispatch = {};
                this.formDispatch = {
                    isOutEntrust: false
                }
                this.dialogVisibleDispatch = true;
                this.getMaintainExecGroup();
                this.maintainExecPersonQuery();
            },
            async getMaintainExecGroup() {
                var param = {
                    userID: this.userID
                }
                const selectRes = await getMaintainExecGroup(param);
                this.maintenance = selectRes.data
            },
            async maintainExecPersonQuery() {
                var param = {
                    userID: this.userID
                }
                const selectRes = await maintainExecPersonQuery(param);
                this.maintenancePeople = selectRes.array
            },
            async confirmChoicesEdit() {
                //this.dialogVisibleDispatch = false;
                var param = {
                    execPerson:this.formDispatch.execPerson,
                     isOutEntrust:this.formDispatch.isOutEntrust,
                    execGroup:this.formDispatch.execGroup,
                    execEstimateLen:this.formDispatch.execEstimateLen,
                    assignTakeTime:this.formDispatch.assignTakeTime,
                    assignEndTime:this.formDispatch.assignEndTime,
                   
                }
                if (this.formDispatch.isOutEntrust) {
                    param.ID = this.currentID;
                    param.isOutEntrust = this.formDispatch.isOutEntrust;

                } else {
                    param = this.formDispatch;
                    console.log("------1",this.formStatement)
                    console.log("------2",param)
                    if(!param.execPerson || !param.execGroup || param.execGroup == "" || !param.execEstimateLen  || param.execEstimateLen == "" || !param.assignTakeTime || param.assignTakeTime == "" || !param.assignEndTime || param.assignEndTime == "" ){
                        this.$message.error("请填写必填项！");
                        return;
                    }
                    this.formDispatch.ID = this.currentID;
                    this.formDispatch.execPerson = this.formDispatch.execPerson.join(',')
                    param = this.formDispatch;
                    
                }
                const selectRes = await maintainOrderDispatch(param);
                console.log("--------3",selectRes)
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogVisibleDispatch = false;
                    this.maintainOrderMultiAuthQueryLimit();
                    this.formDispatch = {}
                } else {
                    // this.$message.error(selectRes.message+",请填写必填项！");
                     this.$message.error("请填写必填项！");
                }
                //  if (this.formDispatch.isOutEntrust) {
                //     param.ID = this.currentID;
                //     param.isOutEntrust = this.formDispatch.isOutEntrust;

                // } else {
                //     this.formDispatch.ID = this.currentID;
                //     this.formDispatch.execPerson = this.formDispatch.execPerson.join(',')
                //     param = this.formDispatch;
                // }
            },
            // //  派单--结束
            // 接单--开始
            async receivingOrders(id) {
                // if (this.multipleSelection.join(',') == '') {
                //     this.$message({
                //         message: '警告：请选择需要接单的数据！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                // if (this.multipleSelection.length > 1) {
                //     this.$message({
                //         message: '警告：请选择一条需要接单的数据！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.$confirm('此操作为接单操作, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.maintainOrderTake(id);
                }).catch(() => {});
            },
            async maintainOrderTake(id) {
                var param = {
                    ID: id
                }
                const selectRes = await maintainOrderTake(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.maintainOrderMultiAuthQueryLimit();
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            // 接单--结束
            // 执行--开始
            implementOperation(id) {
                this.currentID = id;
                this.formImplement = {};
                // if (this.multipleSelection.join(',') == '') {
                //     this.$message({
                //         message: '警告：请选择需要执行的工单！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                // if (this.multipleSelection.length > 1) {
                //     this.$message({
                //         message: '警告：请选择一条需要执行的工单！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.dialogVisibleImplement = true;
                this.maintainOrderExecuteTypeQuery();
            },
            async maintainOrderExecuteTypeQuery() {
                const selectRes = await maintainOrderExecuteTypeQuery();
                this.executeType = selectRes.array;
            },
            async confrimImplement() {
                this.formImplement.ID = this.currentID
                const selectRes = await maintainOrderExecute(this.formImplement);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogVisibleImplement = false;
                    this.maintainOrderMultiAuthQueryLimit();
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            // // 执行--结束

            // 暂离--开始
            temporarilyPart(id) {
                // if (this.multipleSelection.join(',') == '') {
                //     this.$message({
                //         message: '警告：请选择需要暂离的工单',
                //         type: 'warning'
                //     });
                //     return
                // }
                // if (this.multipleSelection.length > 1) {
                //     this.$message({
                //         message: '警告：请选择一条需要暂离的工单！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.$confirm('此操作为暂离工单操作, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.maintainOrderPause(id);
                }).catch(() => {});
            },
            async maintainOrderPause(id) {
                var param = {
                    ID: id
                }
                const selectRes = await maintainOrderPause(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.maintainOrderMultiAuthQueryLimit();
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            // 暂离--结束
            // 暂离结束--开始
            temporarilyPartEnd(id) {
                // if (this.multipleSelection.join(',') == '') {
                //     this.$message({
                //         message: '警告：请选择需要暂离结束的工单',
                //         type: 'warning'
                //     });
                //     return
                // }
                // if (this.multipleSelection.length > 1) {
                //     this.$message({
                //         message: '警告：请选择一条需要暂离结束的工单！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.$confirm('此操作为暂离结束工单操作, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.maintainOrderContinue(id);
                }).catch(() => {});
            },
            async maintainOrderContinue(id) {
                var param = {
                    ID: id
                }
                const selectRes = await maintainOrderContinue(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.maintainOrderMultiAuthQueryLimit();
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            // 暂离结束--结束

            // 保养工单结单--开始
            orderStatement(row) {
                console.log(row)
                this.currentID = row.ID
                // if (this.multipleSelection.join(',') == '') {
                //     this.$message({
                //         message: '警告：请选择需要结单的工单',
                //         type: 'warning'
                //     });
                //     return
                // }
                // if (this.multipleSelection.length > 1) {
                //     this.$message({
                //         message: '警告：请选择一条需要结单的工单！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                 this.formStatement = {};
                this.formStatement = {
                    machineStop: false
                }
                this.dialogVisibleStatement = true;
                this.maintainOrderFinishTypeQuery(row.orderStatus);
            },
            async maintainOrderFinishTypeQuery(status) {
                const selectRes = await maintainOrderFinishTypeQuery();
                // this.finishTypeQuery = selectRes.array;
                if (status == 7) { // 常规保养结单操作
                    var authorityadmin = selectRes.array.filter(itmer => itmer.value == 0);
                    console.log(authorityadmin)
                    this.finishTypeQuery = authorityadmin
                }
                if (status == 10) { //验证不通过-保养结单操作
                    var authorityadmin = selectRes.array.filter(itmer => itmer.value == 1);
                    console.log(authorityadmin)
                    this.finishTypeQuery = authorityadmin
                }
            },
            async confirmStatement() {
                // this.formStatement.ID = this.currentID
                var param = {
                    ID: this.currentID,
                    orderFinishType: this.formStatement.orderFinishType,
                    machineStop: this.formStatement.machineStop,
                    execDesc: this.formStatement.execDesc,
                    spotCondition: this.formStatement.spotCondition,
                    execFee: this.formStatement.execFee,
                    remarks: this.formStatement.remarks,
                    stopLen:this.formStatement.stopLen
                }
                // if(!param.orderFinishType || param.orderFinishType=="" ){
                //     this.$message.error("请选择结单类型");
                //     return
                // }else if(!param.machineStop || param.machineStop=="" ){
                //     this.$message.error("请选择勾选是否停机！");
                //     return
                // }else if(!param.execDesc || param.execDesc=="" ){
                //     this.$message.error("请填写工作描述");
                //     return
                // }else if(!param.spotCondition || param.spotCondition==""){
                //      this.$message.error("请填写现场情况");
                //     return
                // }else if(!param.execFee || param.execFee==""){
                //     this.$message.error("请填写维修费用");
                //     return
                // }else if(!param.stopLen || param.stopLen==""){
                //      this.$message.error("请勾选停机时长");
                //     return
                // }
                // this.formStatement.ID = this.state
                if (this.formStatement.machineStop) {
                    param.stopLen = this.formStatement.stopLen;
                }
                const selectRes = await maintainOrderMaintainFinish(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogVisibleStatement = false;
                    this.formStatement = {};
                    this.maintainOrderMultiAuthQueryLimit();
                } else {
                    this.$message.error("请填写必填项！");
                }
            },
        //      confirmStatement(formName){
        //          this.$refs[formName].validate((valid) => {
        //   if (valid) {
        //     alert('提交成功!');
        //   } else {
        //     console.log('error submit!!');
        //     return false;
        //   }
        // });
        //     },
            // 保养工单结单--结束
            // 验证--开始maintainOrderVerify
            verificationOperation(id) {
                // if (this.multipleSelection.join(',') == '') {
                //     this.$message({
                //         message: '警告：请选择需要验证的工单',
                //         type: 'warning'
                //     });
                //     return
                // }
                // if (this.multipleSelection.length > 1) {
                //     this.$message({
                //         message: '警告：请选择一条需要验证的工单！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.verification = id;
                this.formStatement = {};
                this.formStatement = {
                    verifyResult: false
                };
                this.dialogVisibleVerification = true;
            },
            async confirmVerification() {
                this.dialogVisibleVerification = false;
                this.formStatement.ID = this.verification
                const selectRes = await maintainOrderVerify(this.formStatement);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogVisibleVerification = false;
                    this.formStatement = {};
                    this.maintainOrderMultiAuthQueryLimit();
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            // 是否为经验性工单   maintainOrderMarkEmpirical
            empiricalOrder(id) {
                // if (this.multipleSelection.join(',') == '') {
                //     this.$message({
                //         message: '警告：请选择需要设置经验性的工单',
                //         type: 'warning'
                //     });
                //     return
                // }
                // if (this.multipleSelection.length > 1) {
                //     this.$message({
                //         message: '警告：请选择一条需要设置经验性的工单！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.$confirm('此操作为设置经验性工单操作, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.maintainOrderMarkEmpirical(id);
                }).catch(() => {});
            },
            async maintainOrderMarkEmpirical(id) {
                var param = {
                    ID: id
                }
                const selectRes = await maintainOrderMarkEmpirical(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.maintainOrderMultiAuthQueryLimit();
                } else {
                    this.$message.error(selectRes.message);
                }
            },

            // 工单评分 MaintainOrderMaintainGrade
            scoreOperation(id) {
                this.currentID = id
                // if (this.multipleSelection.join(',') == '') {
                //     this.$message({
                //         message: '警告：请选择需要评分的工单',
                //         type: 'warning'
                //     });
                //     return
                // }
                // if (this.multipleSelection.length > 1) {
                //     this.$message({
                //         message: '警告：请选择一条需要评分的工单！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.formStatement = {};
                this.dialogVisibleScore = true;
            },
            async confirmScore() {
                this.formStatement.ID = this.currentID
                const selectRes = await maintainOrderMaintainGrade(this.formStatement);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogVisibleScore = false;
                    this.formStatement = {};
                    this.maintainOrderMultiAuthQueryLimit();
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }
    }
</script>

<style scoped>

</style>
<style lang="less" scoped>
    ::v-deep .el-form-item__label {
        line-height: 22px;
    }

</style>